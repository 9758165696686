.product_card_container {
  // BC TODO
  border: none;

  .card-body {
    padding: 1rem 0rem;
    a {
      // color: $lucid_black;
      color: #1c1b1b;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 900;
      transition: color 0.2s ease-in-out;
      // margin: 0px 1px;
    }
    h3 {
      padding: 0px;
      font-size: 13px;
      font-weight: 100;
      box-sizing: border-box !important;
      -webkit-font-smoothing: antialiased;
      text-transform: uppercase;
    }
  }
}
.lucid_button {
  margin-top: 15px;
}

.keikei_button {
  @include btn_hover;
  @include color_8;

  &:hover {
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  border-radius: 50px;
  margin-left: 0px;
  margin-right: 0px;
}

.navbar-dark .navbar-nav {
  .nav-link {
    color: $lucid_gray;

    &:focus,
    &:hover,
    &:active {
      color: $lucid_black;
    }
  }
  .show > .nav-link {
    color: $lucid_black;
  }
}

.form-inline {
  input,
  button {
    border-radius: 50px;
  }
}
.navbar svg {
  width: 100px;
  margin-right: 10px;
  padding-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
  .cls-1 {
    fill: $lucid_gray;
    &:hover {
      fill: #000;
    }
  }
}
.search_button {
  color: #000;
  color: #fff;
  margin: 0px;
  border-color: #000;
  width: inherit;
  font-size: inherit;

  &:hover {
    // color: rgb(4, 4, 4);
    background-color: #000;
    border-color: $lucid_gray;
  }
}

// .search_button {
//   color: #000;
//   border: 1px solid #000;
//   background-color: #fff;
//   &:active {
//     color: #000;
//     color: red;
//     background-color: #fff;
//   }
//   &:focus {
//     color: #000;
//     color: blue;
//     background-color: #fff;

//     &:hover {
//       // color: rgb(4, 4, 4);
//       color: #fff;
//     }
//   }
//   &:hover {
//     // color: rgb(4, 4, 4);
//     background-color: #000;
//     border-color: $lucid_gray;
//   }
// }

h3 {
  font-family: 'Varela', sans-serif;
}

.product_image {
  width: 100%;
  height: auto;

  background-color: #cccccc;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.product_headline {
  margin: 50px;
}
video {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
}

.product_screen_card {
  border: none !important;
}

.nav_bar_wrapper {
  &.hover {
    cursor: pointer;
  }
}
/// PAYMENT METHOD

.payment_method {
  .form-label {
    display: flex;
    justify-content: center;
  }
}

// order screen
.wrap_h1 {
  word-wrap: break-word;
}

.background {
  body,
  .background {
    /* height: 100%; */
    width: 100%;
    background: #fff;
  }

  .categoryContainer {
    margin: 20px 0px;
    text-align: center;
  }

  .logic_divider {
    height: 5px;
    width: 100%;
    background: #000;
    margin: 20px 0px;
    text-align: center;
  } /* NAV */
  /* OVER RIDES */
  /* OVER RIDES */
  /* OVER RIDES */

  .navbar a {
    color: #505050;
    font-weight: 700;
  }

  .the-white-out {
    color: #505050 !important;
  }

  .iceWhite {
    color: #505050 !important;
  }

  .navbar-global .navbar-toggle li {
    display: block;
  }

  .navbar-global .navbar-toggle .icon-bar {
    background-color: #505050;
    background-color: #fff;
  }

  .nav > li > a:focus,
  .nav > li > a:hover {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .dropdown-header {
    color: #fff;
  }

  @media (max-width: 767px) {
    .dropdown-header {
      color: #505050;
    }

    .dropdown-menu li > a {
      color: #505050;
    }
  }
  .navbarContainer {
    display: block;
    position: relative;
    height: 100px;
  }

  .le_spacer {
    margin: 50px 0px;
  }

  .glass_button {
    float: right;
  }

  .backButton {
    margin: 10px 0px;
  }

  .backButton a i {
    font-size: 25px;
    font-weight: lighter;
    color: black;
  }
}
