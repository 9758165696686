// variables
@mixin bp($class) {
  @if $class == mobile {
    @media (min-width: 640px) {
      @content;
    }
  } @else if $class == tablet {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $class == laptop {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $class == desktop {
    @media (min-width: 1280px) {
      @content;
    }
  } @else if $class == cinema {
    @media (min-width: 1536px) {
      @content;
    }
  }
}

// Variables
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
$g-font-family: 'Roboto Condensed', sans-serif;
$g-line-height: 1.5 !default;
$g-spacing: $g-line-height * 1em;
$black: #000;
$white: #fff;
$g-background-color-dark: #18181b;

// global
body,
html {
  .swiper {
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    line-height: 1.2;
    background: $g-background-color-dark;
    font-family: $g-font-family;
    font-weight: 300;
    box-sizing: border-box;
    p {
      line-height: 1.6;
    }
    img {
      width: 100%;
      height: auto;
    }
    .button {
      position: relative;
      display: table;
      padding: $g-spacing / 2 $g-spacing * 2;
      // background: #000;
      color: #fff;
      text-decoration: none;
      margin-top: $g-spacing;
      text-transform: uppercase;
      &:hover {
        // background: lighten(#222, 5%);
      }
    }
  }
}
@mixin keikei_button {
  &:hover {
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  border-radius: 50px;
  margin-left: 0px;
  margin-right: 0px;
  @include color_8;
}
.swiper {
  width: 100vw;
  height: 100vh;
  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    // background: rgba(0, 0, 0, 0.6);
    margin: 0;
    @include bp(mobile) {
      // background: rgba(0, 0, 0, 0.1);
    }
  }
  .swiper-image {
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin: 0;
    transform: scale3d(1, 1, 1); // 3d to hardware accelerate
    backface-visibility: hidden !important;
    will-change: transform;
    transition: transform 1500ms ease;
    transition-delay: 0.4s;
  }
  .content-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: '.';
    height: 75%;
    height: 100%;
    width: 100%;
    justify-items: start;
    align-items: end;
    &.content-right {
      @include bp(mobile) {
        justify-items: end;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      h1 {
        color: #fff !important;
      }
      padding: $g-spacing;
      color: #fff;
      opacity: 0;
      width: 100%;
      max-width: 400px;
      transition-duration: 1.5s, 0.6s;
      transition-property: opacity, transform;
      transition-delay: 0.4s;

      :first-child {
        margin: 0;
      }
      @include bp(mobile) {
        padding: 0 5%;
      }
    }
  }
  .swiper-slide {
    margin: 0;
    overflow: hidden !important;
    &.swiper-slide-active {
      .swiper-image {
        transform: scale3d(1.02, 1.02, 1);
        will-change: transform;
      }
      .content {
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }
  }
  .swiper-nav-wrapper {
    position: absolute;
    bottom: $g-spacing * 4;
    right: $g-spacing * 4;
    @include bp(mobile) {
      bottom: $g-spacing * 3;
    }
    .swiper-button-next {
      right: 0;
      left: 0;
    }
    .swiper-button-prev {
      right: 15px;
      left: auto;
    }
    .swiper-button-next,
    .swiper-button-prev {
      @include keikei_button;
      // margin: 0px;
      border-radius: 50px;
      background: rgba(0, 0, 0, 0.5);
      width: 60px;
      height: 60px;
      &:hover {
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='16px' viewBox='0 0 9 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='chevron-right' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M8.674805,7.066406 L1.924805,0.316406 C1.696288,0.105468 1.432619,0 1.133789,0 C0.834959,0 0.57129,0.105468 0.342773,0.316406 C0.114257,0.544923 0,0.808592 0,1.107422 C0,1.406251 0.114257,1.669921 0.342773,1.898438 L6.301758,7.857422 L0.342773,13.816406 C0.114257,14.044923 0,14.308592 0,14.607422 C0,14.906251 0.114257,15.169921 0.342773,15.398438 C0.465821,15.521485 0.584472,15.609375 0.69873,15.662109 C0.812989,15.714844 0.958007,15.741211 1.133789,15.741211 C1.309571,15.741211 1.454589,15.714844 1.568848,15.662109 C1.683106,15.609375 1.801757,15.521485 1.924805,15.398438 L8.674805,8.648438 C8.903321,8.419921 9.017578,8.156251 9.017578,7.857422 C9.017578,7.558592 8.903321,7.294923 8.674805,7.066406 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
  left: 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  right: auto;
  width: 60px !important;
  height: 60px !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='16px' viewBox='0 0 9 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='chevron-right' transform='translate(4.508789, 7.870605) rotate(-180.000000) translate(-4.508789, -7.870605) translate(-0.000000, -0.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M8.674805,7.066406 L1.924805,0.316406 C1.696288,0.105468 1.432619,0 1.133789,0 C0.834959,0 0.57129,0.105468 0.342773,0.316406 C0.114257,0.544923 0,0.808592 0,1.107422 C0,1.406251 0.114257,1.669921 0.342773,1.898438 L6.301758,7.857422 L0.342773,13.816406 C0.114257,14.044923 0,14.308592 0,14.607422 C0,14.906251 0.114257,15.169921 0.342773,15.398438 C0.465821,15.521485 0.584472,15.609375 0.69873,15.662109 C0.812989,15.714844 0.958007,15.741211 1.133789,15.741211 C1.309571,15.741211 1.454589,15.714844 1.568848,15.662109 C1.683106,15.609375 1.801757,15.521485 1.924805,15.398438 L8.674805,8.648438 C8.903321,8.419921 9.017578,8.156251 9.017578,7.857422 C9.017578,7.558592 8.903321,7.294923 8.674805,7.066406 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
  auto: 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  right: left;
  width: 60px !important;
  height: 60px !important;
}

.swiper-image-bottom {
  background-size: cover;
}
.swiper-image-two {
  background-size: cover;
}
.swiper-image-three {
  background-size: cover;
}

.sss {
  background-size: cover;
}
