@import url('https://fonts.googleapis.com/css2?family=Moon+Dance&family=Varela&family=Vujahday+Script&display=swap');
.notification_box {
  // font-family: Moon Dance;
  // font-family: Vujahday Script;
  // font-size: 35px;
  background-color: white;
  color: black;

  // background-color: black;
  // color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  text-align: center;
  // border-bottom: 0.1px black solid;
}
@media only screen and (max-width: 600px) {
  .notification_box {
    font-size: 12px;
  }
}
