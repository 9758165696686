@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');
$lucid_gray: #777;
$lucid_black: #000;
main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
  // left: 0;
}

.carousel-caption h2 {
  color: #fff;
  color: rgb(26, 26, 26);
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1);
}
.carousel img {
  // height: 300px;
  padding: 30px;
  margin: 40px;
  // border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  max-height: 500px;
}

.carousel a {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}
