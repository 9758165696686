@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

:root {
  --body-color: #2c2d2a;
  --savanna-bg: #e9bf8b;
  --beach-bg: #e7dfcf;
  --glacier-bg: #b6d6c8;
  --coral-bg: #e86357;
  --arrow-fill: #333231;
  --body-font: 'Montserrat', sans-serif;
  --italic-font: 'EB Garamond', serif;
}

* {
  box-sizing: border-box;
  outline: none;
}

// body {
//   font-family: var(--body-font);
//   background-color: #1d1e20;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   padding: 2em;
//   width: 100%;
//   height: 100vh;
//   color: var(--body-color);

//   @media (max-width: 480px) {
//     padding: 0;
//   }
// }

.slider_container {
  // max-width: 1100px;
  // border-radius: 4px;
  max-height: 680px;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  background-color: #e6decf;
  padding: 0 30px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  @media (max-width: 480px) {
    height: 100%;
    max-height: 100%;
  }
  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
    color: var(--body-color);
  }

  h1,
  h2,
  h3 {
    margin: 0;
  }

  .logo {
    width: 116px;
  }
  .main-content__subtitle {
    max-width: 300px !important;
  }
  .header {
    display: flex;
    align-items: center;
    height: 62px;
    width: 100%;
    white-space: nowrap;
    flex-shrink: 0;
    font-weight: 600;
    font-size: 15px;
    border-bottom: 1px solid rgba(44, 45, 42, 0.25);
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--beach-bg);
    z-index: 6;

    @media (max-width: 575px) {
      width: calc(100% + 20px);
      margin-left: -10px;
    }

    &-menu {
      display: flex;
      align-items: center;
      margin-left: auto;

      @media screen and (max-width: 740px) {
        display: none;
      }

      a:not(:first-child) {
        margin-left: 30px;
      }
    }

    .menu-icon {
      display: flex;
      align-items: center;
      margin-right: 20px;

      svg {
        width: 22px;
      }
    }

    &-icons {
      margin-left: auto;
      display: flex;
      align-items: center;

      svg {
        width: 18px;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // max-width: 320px;
    // width: 50%;
    // align-items: center;
    @media screen and (max-width: 930px) {
      text-align: center;
      max-width: 450px;
    }
  }

  .candleSwiper {
    display: flex;
    flex-grow: 1;
    position: relative;
  }

  .main {
    padding: 42px 0 30px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    position: relative;

    @media screen and (max-width: 930px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    &-header {
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 4px;
      font-weight: 600;
      transition-delay: 0.2s;
    }

    &-title {
      font-family: var(--italic-font);
      font-size: 100px;
      font-weight: 400;
      margin-top: 10px;
      line-height: 1em;
      transition-delay: 0.3s;
    }

    &-subtitle {
      font-family: var(--italic-font);
      font-weight: 400;
      font-size: 32px;
      margin-top: 14px;
      margin-bottom: 60px;
      transition-delay: 0.4s;
    }

    &-content {
      &__title {
        font-size: 26px;
        font-family: var(--italic-font);
        font-style: italic;
        margin-bottom: 14px;
        transition-delay: 0.2s;
      }

      &__subtitle {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 24px;
        letter-spacing: -0.01em;
        transition-delay: 0.3s;
      }

      .more-menu {
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        transition-delay: 0.4s;

        @media screen and (max-width: 930px) {
          justify-content: center;
        }

        svg {
          width: 28px;
          height: 18px;
          margin-left: 10px;
        }
      }
    }
  }

  .center {
    display: flex;
    margin-left: 120px;
    position: relative;
    flex-shrink: 0;

    @media screen and (max-width: 930px) {
      margin-left: 0;
      margin-bottom: 56px;
    }

    .bottle-bg {
      width: 320px;
      height: 450px;
      object-fit: cover;
      border-radius: 160px;

      @media screen and (max-width: 930px) {
        width: 260px;
        height: 390px;
      }
      @media screen and (max-width: 575px) {
        width: 220px;
        height: 340px;
      }
    }

    .bottle-img {
      position: absolute;
      top: 25%;
      left: 0;
      transform: scale(1.6);
    }
  }

  .swiper-pagination {
    position: absolute;
    right: 30px;
    left: auto;
    top: 100px;
    width: auto;
    bottom: auto;
    z-index: 2;
    font-size: 14px;
    font-family: var(--body-font);
    font-weight: 500;
  }

  .button-wrapper {
    position: absolute;
    right: 30px;
    bottom: 20px;
    z-index: 1;
    display: flex;
    align-items: center;

    @media screen and (max-width: 930px) {
      top: 0;
      left: 0;
      width: 100%;
      justify-content: space-between;
      padding: 0 60px;
    }

    @media screen and (max-width: 575px) {
      padding: 0 20px;
    }

    svg {
      width: 28px;
    }

    .swiper-button {
      border: 1px solid var(--body-color);
      border-radius: 50%;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        to right,
        var(--body-color) 40%,
        transparent 0%
      );
      background-size: 200% 100%;
      background-position: right bottom;
      transition: all 0.3s ease-out;
      cursor: pointer;

      & + .swiper-button {
        margin-left: 16px;

        @media screen and (max-width: 930px) {
          margin-left: 0;
        }
      }

      &:hover {
        background-color: var(--body-color);
        background-position: left bottom;
        svg {
          stroke: #fff;
        }
      }
    }

    .swiper-prev-button {
      background: linear-gradient(
        to left,
        var(--body-color) 40%,
        transparent 0%
      );
      background-size: 200% 100%;
      background-position: left bottom;
      transition: all 0.3s ease-out;
      svg {
        transform: rotate(-180deg);
      }
      &:hover {
        background-position: right bottom;
      }
    }
  }

  .swiper-slide {
    opacity: 0 !important;
    transition: 0.4s;
    &-active {
      opacity: 1 !important;
    }
  }

  .swiper-slide .main-wrapper > *,
  .swiper-slide .main-content > * {
    transform: translateY(-30px);
    opacity: 0;
    transition-duration: 0.8s;
  }

  .swiper-slide-active .main-wrapper > *,
  .swiper-slide-active .main-content > * {
    transform: none;
    opacity: 1;
  }

  .swiper-slide .bottle-bg {
    transition-duration: 0.6s;
    opacity: 0;
    object-position: 60%;
  }

  .swiper-slide-active .bottle-bg {
    opacity: 1;
    transform: none;
    object-position: 50%;
  }

  .swiper-slide .bottle-img {
    transition-duration: 0.8s;
    transform: scale(1.2);
    opacity: 0;
  }

  .swiper-slide-active .bottle-img {
    opacity: 1;
    transform: scale(1.6);
  }

  [data-sld='1'] {
    .container,
    .header {
      background-color: var(--savanna-bg);
    }
  }

  [data-sld='2'] {
    .container,
    .header {
      background-color: var(--glacier-bg);
    }
  }

  [data-sld='3'] {
    .container,
    .header {
      background-color: var(--coral-bg);
    }
  }
}
