.jumbotron {
  //   min-height: 500px;
  //   padding-top: 80px;
  margin-top: 80px;
  height: 500px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  //   height: 100vh;
  //   .jumbron_image_one {
  //     background-image: url("../images/candles/LUXE_ONE_1600.jpg");
  //     background-size: cover;
  //     height: 100%;
  //     width: 100%;
  //     transition: transform 0.2s;
  //   }

  //

  .image-box {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 100%;
    z-index: 0;
  }

  .jumbo_text {
    position: absolute;
    // margin: 25px;
    overflow: hidden;
    // width: 100%;
    color: white;
    z-index: 1;
    left: 25px;
    right: 25px;
    padding: 25px;
    // background-color: red;
    width: 50%;
    h1 {
      font-family: "Gruppo";
      font-size: 75px;
    }
  }
  .image-box img {
    max-width: 100%;
    transition: all 0.3s;
    display: block;
    width: 100%;
    height: auto;
    transform: scale(1);
  }

  .image-box:hover img {
    transform: scale(1.1);
  }
}

.css-selector {
  background: linear-gradient(270deg, #b46ed8, #f1cfef, #f4d4ce, #f4ecce);
  background-size: 800% 800%;

  -webkit-animation: lucidGradient 14s ease infinite;
  -moz-animation: lucidGradient 14s ease infinite;
  -o-animation: lucidGradient 14s ease infinite;
  animation: lucidGradient 14s ease infinite;
}

@-webkit-keyframes lucidGradient {
  0% {
    background-position: 9% 0%;
  }
  50% {
    background-position: 92% 100%;
  }
  100% {
    background-position: 9% 0%;
  }
}
@-moz-keyframes lucidGradient {
  0% {
    background-position: 9% 0%;
  }
  50% {
    background-position: 92% 100%;
  }
  100% {
    background-position: 9% 0%;
  }
}
@-o-keyframes lucidGradient {
  0% {
    background-position: 9% 0%;
  }
  50% {
    background-position: 92% 100%;
  }
  100% {
    background-position: 9% 0%;
  }
}
@keyframes lucidGradient {
  0% {
    background-position: 9% 0%;
  }
  50% {
    background-position: 92% 100%;
  }
  100% {
    background-position: 9% 0%;
  }
}
