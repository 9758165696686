.nav_wrapper {
  // color: #fff0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;

  position: fixed;
  background-color: azure;
  width: 100%;
  font-family: "Gruppo";
  font-size: 25px;
  z-index: 100;
  //@at-root
  top: 0;
  // z-index: 100;
  // opacity: 1;
  // background-color: white;
  width: 100%;
  //

  h1 {
    text-align: center;
  }

  ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    li {
      padding: 10px;
    }
  }
}
